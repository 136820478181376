const bottomLogos = require('../assets/combinedLogos.png');

const PageWrapper = (props) => {
  return (
    <div className="pageWrapper">
      <div className="flex-center direction-column" style={{ justifyContent: 'space-evenly', height: '50%' }}>
        {props.children}
        <img src={bottomLogos} alt="Combined logos" height={50} style={{ marginTop: 20 }} />
      </div>
    </div>
  );
};

export default PageWrapper;
