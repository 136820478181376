import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import RegPageComponent from './RegPageComponent';
import CheckInComponent from './CheckInComponent';
import EventListingComponent from './EventListingComponent';
import ThankYouComponent from './ThankYouComponent';

import '../styles/App.scss';

const seagenTheme = createTheme({
  typography: {
    fontFamily: "'Avenir', 'Roboto', 'Helvetica', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    blue: {
      main: '#004866',
      contrastText: '#fff'
    },
    turquoise: {
      // main: '#167380',
      main: '#189A94',
      contrastText: '#fff'
    },
    text: {
      primary: '#000',
      gray: '#666666',
      white: '#FFF',
      blue: '#004866',
      turquoise: '#189A94'
    }
    // components: {
    //   MuiButton: {
    //     styleOverrides: {
    //       outlined: {
    //         backgroundColor: '#167380'
    //       }
    //     }
    //   }
    // }
  },
  overrides: {
    // MuiInputLabel: {
    //   root: {
    //     opacity: '1',
    //     color: '#000'
    //   }
    // },
    MuiFormLabel: {
      root: {
        color: '#000'
      }
    }
  }
});

const AppRouter = () => {
  const routes = useRoutes([
    { path: '/', element: <EventListingComponent /> },
    { path: '/:sessionCode', element: <RegPageComponent /> },
    { path: '/:sessionCode/thank-you', element: <ThankYouComponent /> },
    { path: '/check-in', element: <CheckInComponent /> },
    { path: '/check-in/:sessionCode', element: <CheckInComponent /> }
  ]);
  return routes;
};

const App = () => {
  return (
    <ThemeProvider theme={seagenTheme}>
      <div className="flex wrapper">
        <Router>
          <AppRouter />
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
