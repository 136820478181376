import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';

import SelectDropdown from './formFields/SelectDropdown';

import styles from '../styles/landingPage.module.scss';
import config from '../config';

const SessionSelect = (props) => {
  const [events, setEvents] = useState([]);
  const { control, handleSubmit, watch } = useForm({ defaultValues: { sessionCode: '' } });
  const watchSessionSelect = watch('sessionCode', null);

  useEffect(() => {
    fetch(`${config.baseUrl}/api/events`)
      .then((res) => res.json())
      .then((data) => {
        setEvents(data);
      });
  }, []);

  const { onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      <SelectDropdown
        control={control}
        name="sessionCode"
        placeholder="Select session"
        options={events.map((event) => ({
          id: event.code,
          name: `${event.name} - ${new Date(event.date).toDateString()}`
        }))}
        fullWidth
        wrapperStyles={{ borderRadius: 5 }}
      />
      <Button
        type="submit"
        variant="contained"
        color="blue"
        sx={{ marginTop: 10, cursor: !watchSessionSelect && 'not-allowed' }}
        size="large"
      >
        {props.buttonLabel}
      </Button>
    </form>
  );
};

export default SessionSelect;
