import { TextField, InputLabel } from '@mui/material';

import { Controller } from 'react-hook-form';

const TextInput = ({
  control,
  name,
  type,
  label,
  rules,
  fullWidth,
  wrapperStyles,
  inputStyles,
  helperText,
  error,
  errorMessage
}) => {
  // console.log(name, 'fullWidth', fullWidth, Boolean(fullWidth));
  // if (error && name === 'postal_code') {
  //   console.log(name, 'error', error);
  // }

  return (
    <div style={{ width: fullWidth && '100%', margin: 5, ...wrapperStyles }}>
      <div className="flex">
        <InputLabel className="formLabel">
          {`${label}${rules && rules.required ? '*' : ''}`}
        </InputLabel>
        {error && (
          <span style={{ fontSize: 14, color: '#d32f2f', marginLeft: 20, fontStyle: 'italic' }}>
            {errorMessage}
          </span>
        )}
      </div>

      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field: { ...field } }) => {
          // console.log('field', field);
          return (
            <TextField
              {...field}
              color="blue"
              type={type}
              fullWidth={fullWidth}
              size="small"
              sx={{ ...inputStyles }}
              helperText={helperText}
              error={Boolean(error)}
            />
          );
        }}
      />
    </div>
  );
};

export default TextInput;
