import { useEffect, useState } from 'react';
import _ from 'lodash';
import { experimentalStyled as styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { Dialog, Button, Grid, Typography, Paper } from '@mui/material';
import config from '../config';

// const buttonStyle = {
//   backgroundColor: '#EFEFEF',
//   color: '#000000',
//   borderRadius: 3,
//   border: '1px solid #000000',
//   height: 30,
//   width: 75,
//   padding: '1px 6px',
//   cursor: 'pointer'
// };

const HcpElem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  minHeight: 200
}));

const NpiLookup = (props) => {
  const { getFormValues, modalOpen, handleModalClose, setNpiInfo } = props;

  const [hcpList, setHcpList] = useState(null);
  const [loading, setLoading] = useState(false);

  // const validateNpi = (value) => {
  //   return /^\d+$/.test(value) && value.length === 10;
  // };

  useEffect(() => {
    const getHcps = () => {
      if (modalOpen) {
        const formValues = getFormValues();
        const baseUrl = `${config.baseUrl}/api/npilookup?`;
        const params = _.filter(Object.keys(_.pickBy(formValues, _.identity)), (key) => {
          return _.includes(['first_name', 'last_name', 'npi', 'state'], key);
        })
          .map((key) => {
            return `${key}=${formValues[key].toString().trim()}`;
          })
          .join('&');

        const req = fetch(`${baseUrl}${params}`);

        req.then((response) => {
          response.json().then((json) => {
            if (_.isEmpty(json.results)) {
              setHcpList([]);
            } else {
              setHcpList(json.results);
            }
            setLoading(false);
          });
        });
        req.catch((err) => {
          console.log('err on getting api is', err);
          setLoading(false);
          setNpiInfo(null);
        });
      }
    };
    getHcps();
  }, [modalOpen, getFormValues, setNpiInfo]);

  const handleClose = () => {
    handleModalClose();
    setHcpList(null);
    setLoading(false);
  };

  const handleSelect = (hcp) => {
    const { addresses, taxonomies } = hcp;
    const npiInfo = {
      // first_name: basic.first_name,
      // last_name: basic.last_name,
      npi: hcp.number,
      middle_name: hcp.basic.middle_name || '',
      degree: hcp.basic.credential || ''
    };
    if (taxonomies.length) {
      npiInfo.license_state = hcp.taxonomies[0].state;
      npiInfo.license_number = hcp.taxonomies[0].license;
    }
    if (addresses.length) {
      npiInfo.address_1 = addresses[0].address_1;
      npiInfo.phone_number = addresses[0].telephone_number;
      npiInfo.city = addresses[0].city;
      npiInfo.state = addresses[0].state;
      npiInfo.postal_code = addresses[0].postal_code;
    }
    if (taxonomies.length) {
      npiInfo.professional_title = taxonomies[0].desc;
    }
    setNpiInfo(npiInfo);
  };

  const renderHcps = () => {
    if (loading) {
      return <div>Loading...</div>;
    }
    // if (hcpList && !hcpList.length) {
    //   return (
    //     <div style={{ height: 200, textAlign: 'center' }}>
    //       <div style={{ margin: 50 }}>No records found.</div>
    //       <br />
    //       <br />
    //     </div>
    //   );
    // }
    let rows = [];
    if (_.isArray(hcpList)) {
      rows = _.map(hcpList, (hcp) => {
        const { basic, addresses, taxonomies } = hcp;
        // console.log('hcp is', hcp);

        const primaryTaxonomy = _.find(taxonomies, { primary: true });

        return (
          <HcpElem>
            <div className="flex direction-column" style={{ height: 225 }}>
              <div className="flex direction-column">
                <b>
                  {`${basic.first_name} ${basic.middle_name ? basic.middle_name : ''} ${
                    basic.last_name
                  } ${basic.credential ? basic.credential : ''}`}
                </b>

                <b>{hcp.number}</b>
                <br />
                {hcp.addresses.length && (
                  <div>
                    {addresses[0].address_1}
                    <br />
                    {addresses[0].city}, {addresses[0].state} {addresses[0].postal_code}
                    <br />
                  </div>
                )}
                {primaryTaxonomy && <div>{primaryTaxonomy.desc}</div>}
              </div>
              <Button
                variant="contained"
                color="blue"
                onClick={() => handleSelect(hcp)}
                sx={{
                  width: 200,
                  marginTop: 5,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textTransform: 'none'
                }}
              >
                Select
              </Button>
            </div>
          </HcpElem>
        );
      });
    }
    return (
      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 5 }}>
          Please select the record that includes your information
        </Typography>
        {/* <h3 style={{ marginBottom: 20 }}>Please select the record that includes your information:</h3> */}
        {/* {rows} */}
        {rows.length ? (
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
            {rows.map((row, index) => (
              <Grid item xs={4} key={index}>
                {row}
              </Grid>
            ))}
          </Grid>
        ) : (
          <div className="flex-center" style={{ height: 300 }}>
            <Typography variant="p" color="text.blue" sx={{ textAlign: 'center' }}>
              No record found
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return hcpList ? (
    <Dialog
      key={'npiLookupModal'}
      fullWidth
      maxWidth={'md'}
      open={modalOpen}
      onClose={handleClose}
      // sx={{ padding: 'auto', top: 10, textAlign: 'center', width: 'auto', minWidth: 800 }}
    >
      <div style={{ position: 'relative' }}>
        <Button
          className="flex-center direction-column"
          sx={{ position: 'absolute', top: 3, right: 3, color: 'black' }}
          onClick={handleClose}
        >
          <CloseIcon />
          Close
        </Button>
        <div style={{ padding: 20 }}>{renderHcps()}</div>
      </div>
    </Dialog>
  ) : (
    ''
  );
};

export default NpiLookup;
