/* eslint-disable quotes, comma-dangle */

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import * as React from 'react';

import _ from 'lodash';

import { Button, FormLabel } from '@mui/material';

import { useForm } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';

import { TextInput, SelectDropdown, RadioSelect, NpiLookup } from './index';

import styles from '../styles/regPage.module.scss';
import config from '../config';

const sigCanvasSize = { width: 650, height: 200 };

const states = require('./states.json');
const countries = require('./countries.json');

const RegFormComponent = (props) => {
  const { userData, sessionCode, event, submitButtonLabel, isCheckIn, onSubmit } = props;
  const [submitError, setSubmitError] = useState(false);
  const [npiModalOpen, setNpiModalOpen] = useState(false);
  const [sigCanvas, setSigCanvas] = useState(null);
  const [signatureError, setSignatureError] = useState(false);
  const navigate = useNavigate();

  const { control, handleSubmit, watch, formState, getValues, setValue, trigger } = useForm({
    // reValidateMode: 'onBlur',
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      hcp_type: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      suffix: '',
      email: '',
      degree: '',
      professional_title: '',
      institution: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'United States',
      phone_number: '',
      npi: '',
      license_state: '',
      license_number: '',
      opt_out_of_meal: 0,
      signature: '',
      ...userData
    }
  });

  useEffect(() => {
    if (isCheckIn && userData.signature && sigCanvas) {
      sigCanvas.fromDataURL(userData.signature, sigCanvasSize);
    }
    // validateForm();
  }, [sigCanvas, userData, isCheckIn]);

  const watchHcpType = watch('hcp_type') || 'Prescriber with NPI Number';
  const otherWatchType = watch('hcp_type');

  const watchCountry = watch('country') || 'United States';

  useEffect(() => {
    if (watchCountry !== 'United States') {
      setValue('state', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchCountry]);

  const toggleNpiModal = () => {
    setNpiModalOpen(!npiModalOpen);
  };

  const setNpiInfo = (hcpInfo) => {
    // form.setFieldsValue({ ...hcpInfo });
    Object.keys(hcpInfo).forEach((key) => {
      setValue(key, hcpInfo[key]);
      trigger(key);
    });
    toggleNpiModal();
  };

  const openNpiLookupModal = () => {
    const requiredFields = ['first_name', 'last_name', 'state'];
    const isValid = _.every(_.values(_.pick(getValues(), requiredFields)));
    if (isValid) {
      toggleNpiModal();
    } else {
      requiredFields.map((field) => trigger(field));
    }
  };

  const validateForm = () => {
    if (isCheckIn) {
      if (sigCanvas && sigCanvas.isEmpty()) {
        setSignatureError(true);
      } else {
        setSignatureError(false);
      }
    }
    return handleSubmit(onFormSubmit);
  };

  const onFormSubmit = (data) => {
    // data.preventDefault();    data.session_id = sessionCode;
    data.code = sessionCode;
    if (isCheckIn) {
      if (sigCanvas.isEmpty()) {
        setSignatureError(true);
        return;
      }
      data.signature = sigCanvas.getTrimmedCanvas().toDataURL('base64', 0.7);
      delete data.event;
    }

    setSubmitError(false);
    const isAttendeeUpdate = isCheckIn && data.id;

    const apiPath = isAttendeeUpdate ? `/api/registrants/${data.id}` : '/api/registrants';
    fetch(`${config.baseUrl}${apiPath}`, {
      method: isAttendeeUpdate ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => {
      if (!_.includes([200, 201], res.status)) {
        setSubmitError(true);
        console.log('there is an err');
        return;
      }
      res.json().then((attendeeJson) => {
        // console.log('attendeeJson', attendeeJson);
        if (onSubmit) {
          onSubmit(attendeeJson);
          return;
        }
        navigate(`/${event.code}/thank-you/`, { state: { event } });
      });
    });
  };

  const { errors } = formState;

  const renderStateField = (inputStyles, wrapperStyles) => {
    return (
      <SelectDropdown
        control={control}
        disabled={watchCountry !== 'United States'}
        name="state"
        label="State"
        options={states}
        rules={{ required: watchCountry === 'United States' }}
        inputStyles={{ width: '100%', ...inputStyles }}
        wrapperStyles={{ width: '40%', ...wrapperStyles }}
        error={errors.state}
        errorMessage={'Please select a State'}
      />
    );
  };

  // console.log('watchHcpType', watchHcpType);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={styles.formElement}>
      <NpiLookup
        modalOpen={npiModalOpen}
        handleModalClose={toggleNpiModal}
        getFormValues={getValues}
        setNpiInfo={setNpiInfo}
      />

      <div className="flex-center" style={{ width: '100%' }}>
        <SelectDropdown
          name="hcp_type"
          label="Attendee Type"
          placeholder={'Select Attendee Type'}
          options={[
            'Prescriber with NPI Number',
            'Non-Prescriber with State License Number',
            'Non-HCP'
          ]}
          control={control}
          wrapperStyles={{ width: '60%' }}
          fullWidth
        />
      </div>
      <div className={!otherWatchType ? styles.disabledFields : ''}>
        <div
          style={{
            height: 40,
            borderBottom: isCheckIn && '1px solid black',
            marginBottom: isCheckIn && 20
          }}
        />
        <i>* Required fields</i>
        <br />
        <br />
        <div className="flex">
          <TextInput
            name="first_name"
            label="First Name"
            rules={{ required: true }}
            fullWidth
            control={control}
            error={errors.first_name}
            errorMessage={'Please enter a First Name'}
          />
          <TextInput
            name="middle_name"
            label="Middle Name"
            control={control}
            error={errors.middle_name}
          />
        </div>
        <div className="flex">
          <TextInput
            name="last_name"
            label="Last Name"
            rules={{ required: true }}
            fullWidth
            control={control}
            error={errors.last_name}
            errorMessage={'Please enter a Last Name'}
          />
          <TextInput name="suffix" label="Suffix" control={control} error={errors.suffix} />
        </div>

        {watchHcpType === 'Prescriber with NPI Number' && (
          <>
            {renderStateField({ width: '98%' }, { width: '50%' })}
            <TextInput
              name="npi"
              label="NPI Number"
              rules={{ required: true }}
              control={control}
              inputStyles={{ width: '50%' }}
              error={errors.npi}
              errorMessage={'Please enter your NPI Number'}
            />
            <p style={{ fontSize: 14 }}>
              Don&apos;t remember your NPI number?{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={openNpiLookupModal}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#004866'
                }}
              >
                Click here
              </span>{' '}
              to look it up. (Requires completion of the above First Name, Last Name, and State
              fields prior to searching)
            </p>
          </>
        )}

        {watchHcpType === 'Non-Prescriber with State License Number' && (
          <div className="flex">
            <SelectDropdown
              control={control}
              name="license_state"
              label="State of Licensure"
              rules={{ required: true }}
              options={states}
              fullWidth
              error={errors.license_state}
            />
            <TextInput
              name="license_number"
              label="State License Number"
              rules={{ required: true }}
              control={control}
              fullWidth
              error={errors.license_number}
            />
          </div>
        )}

        <TextInput
          name="address_1"
          label="Address Line 1"
          helperText="Street address, PO box, etc"
          rules={{ required: true }}
          fullWidth
          control={control}
          error={errors.address_1}
          errorMessage={'Please enter an Address'}
        />
        <TextInput
          name="address_2"
          label="Address Line 2"
          helperText="Apartment, suite, unit, building, floor, etc"
          fullWidth
          control={control}
          error={errors.address_2}
        />
        <div className="flex">
          <SelectDropdown
            name="country"
            label="Country"
            options={countries}
            control={control}
            fullWidth
            error={errors.country}
          />
          <TextInput
            name="city"
            label="City"
            placeholder={'City'}
            rules={{ required: true }}
            control={control}
            error={errors.city}
            errorMessage={'Please enter a City'}
            fullWidth
          />
        </div>
        <div className="flex">
          {watchHcpType !== 'Prescriber with NPI Number' && renderStateField()}
          <TextInput
            name="postal_code"
            label="ZIP Code"
            rules={{
              required: true,
              maxLength: 9
            }}
            control={control}
            error={errors.postal_code}
            errorMessage={'Please enter a ZIP Code'}
          />
        </div>
        <div className="flex">
          <TextInput
            name="email"
            label="Email Address"
            rules={{
              // required: true,
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }
            }}
            fullWidth
            control={control}
            error={errors.email}
            errorMessage={'Please enter a valid Email Address'}
          />
          <TextInput
            name="phone_number"
            label="Phone Number"
            control={control}
            error={errors.phone_number}
          />
        </div>
        <TextInput
          name="degree"
          label="Degree"
          placeholder={'Degree'}
          fullWidth
          control={control}
          error={errors.degree}
        />
        <TextInput
          name="professional_title"
          label="Professional Title"
          fullWidth
          control={control}
          error={errors.professional}
        />
        <TextInput
          name="institution"
          label="Affilated Institution"
          fullWidth
          control={control}
          error={errors.affilatedInstitution}
        />

        {isCheckIn && (
          <>
            <RadioSelect
              name="opt_out_of_meal"
              label="Do you wish to opt out of a meal?"
              value={getValues().opt_out_of_meal}
              options={[
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
              ]}
              control={control}
            />
            <FormLabel sx={{ color: 'black' }}>Signature*</FormLabel>&nbsp;&nbsp;
            {signatureError && <span style={{ color: 'red' }}>Please provide a Signature</span>}
            <div
              style={{
                border: '1px dashed',
                borderColor: signatureError ? 'red' : 'black',
                padding: 10,
                marginTop: 20,
                width: 700,
                margin: '0 auto'
              }}
            >
              <div style={{ borderBottom: '1px solid gray' }}>
                <SignatureCanvas
                  onBegin={() => setSignatureError(false)}
                  // onEnd={(data) => {
                  //   console.log('signature', data);
                  // }}
                  penColor={'darkblue'}
                  canvasProps={sigCanvasSize}
                  ref={(ref) => {
                    setSigCanvas(ref);
                  }}
                />
              </div>
              <Button
                variant={'contained'}
                color="blue"
                style={{ width: 150, marginTop: 10 }}
                onClick={() => {
                  sigCanvas.clear();
                }}
              >
                Clear
              </Button>
            </div>
          </>
        )}

        <div style={{ fontSize: 14, marginTop: 20, marginBottom: 20 }}>
          In alignment with the Office of Inspector General (OIG) guidance, alcohol will not be
          provided as part of company-sponsored speaker programs. No Continuing Medical Education
          (CME) is offered. This program is intended for US healthcare providers only. Please be
          aware that if you are a licensed healthcare provider in the United States, any meal
          associated with this program is reportable under the Federal Open Payments Act.
          <br />
          <br />
          {!isCheckIn && (
            <>
              For healthcare providers in Colorado, please see our required disclosure{' '}
              <a
                href="https://www.seagen.com/assets/pdfs/Who-We-Are/Ethics-and-Compliance/Seagen-CO-WAC-Tivdak.pdf"
                target={'_blank'}
                rel={'noreferrer'}
                style={{ color: '#004866' }}
              >
                here
              </a>
              .
            </>
          )}
        </div>

        {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}> */}
        {submitError && <p style={{ color: 'red' }}>something went wrong...</p>}
        <Button
          type="submit"
          variant="contained"
          color="blue"
          sx={{ width: '100%', padding: 1, textTransform: 'none' }}
          // onClick={handleSubmit(onFormSubmit)}
          onClick={validateForm}
        >
          {submitButtonLabel || 'Submit'}
        </Button>
        {/* </div> */}
      </div>
    </form>
  );
};

export default RegFormComponent;
