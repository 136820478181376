import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Typography } from '@mui/material';

import { PageWrapper, SessionSelect } from '../common';

import styles from '../styles/landingPage.module.scss';
import config from '../config';

const EventListingComponent = (props) => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${config.baseUrl}/api/events`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length === 1) {
          console.log(data);
          navigate(`/${data[0].code}`);
          return;
        }
        setEvents(data);
      });
  }, []);

  const onSubmit = (data) => {
    navigate(`/${data.sessionCode}`, {
      state: { session: _.find(events, { id: data.sessionCode }) }
    });
  };

  return (
    <PageWrapper className={styles.landingPageWrapper}>
      <Typography variant="h2" color="text.white" sx={{ fontWeight: 500 }}>
        Welcome
      </Typography>
      <div className="flex direction-column horizontal-center vertical-center">
        <Typography variant="p" color="text.white" sx={{ marginBottom: 5, fontWeight: 600 }}>
          Please select a session below
        </Typography>
        <SessionSelect onSubmit={onSubmit} buttonLabel="Begin Registration &gt;" />
      </div>
    </PageWrapper>
  );
};

export default EventListingComponent;
