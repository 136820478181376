import _ from 'lodash';
import { InputLabel, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const SelectDropdown = ({
  control,
  name,
  label,
  rules,
  options,
  fullWidth,
  wrapperStyles,
  // inputStyles,
  error,
  errorMessage
}) => {
  const radioOptions = _.isObject(_.first(options))
    ? options
    : options.map((option) => ({ id: option, name: option }));

  return (
    <div
      style={{ width: fullWidth && '100%', margin: 5, backgroundColor: 'white', ...wrapperStyles }}
    >
      <div className="flex">
        {label && (
          <InputLabel className="formLabel">
            {`${label}${rules && rules.required ? '*' : ''}`}
          </InputLabel>
        )}
        {error && (
          <span style={{ fontSize: 14, color: '#d32f2f', marginLeft: 20, fontStyle: 'italic' }}>
            {errorMessage}
          </span>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ...field } }) => {
          // console.log('field', field);

          return (
            <RadioGroup row {...field}>
              {radioOptions.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={
                    <Radio
                      color="turquoise"
                      sx={{
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                          color: 'black'
                        }
                      }}
                    />
                  }
                  label={option.name}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
    </div>
  );
};

export default SelectDropdown;
