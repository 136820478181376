import { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography, Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import config from '../config';

import { PageWrapper } from '../common';

const ThankYouComponent = () => {
  const location = useLocation();
  const [event, setEvent] = useState(location.state && location.state.session);

  const { sessionCode } = useParams();

  useEffect(() => {
    if (!event) {
      fetch(`${config.baseUrl}/api/events/${sessionCode}`)
        .then((res) => res.json())
        .then((eventJson) => {
          setEvent(eventJson);
        })
        .catch((err) => {
          console.log('error note in useEffect');
          setEvent({ name: 'Default Event', date: '2022-09-09' });
        });
    }
  }, [event, sessionCode]);

  if (!event) {
    return <h2>loading</h2>;
  }

  return (
    <PageWrapper>
      <Typography variant="h2" color="text.white" sx={{ fontWeight: 500, marginBottom: '55px' }}>
        Thanks for Registering!
      </Typography>
      <div
        style={{
          backgroundColor: 'white',
          width: 800,
          borderRadius: 15,
          padding: '20px 70px 50px 70px'
        }}
      >
        <p
          style={{
            borderBottom: '1px solid black',
            textAlign: 'center',
            fontSize: '30px',
            paddingBottom: 20
          }}
        >
          Event Details
        </p>
        <div style={{ marginTop: 30, textAlign: 'center' }}>
          <Typography variant="h5" color="text.turquoise" sx={{ fontWeight: 600 }}>
            {event.name}
          </Typography>
          <div
            className="flex"
            style={{
              justifyContent: 'space-between',
              minWidth: 300,
              maxWidth: '60%',
              margin: '30px auto',
              fontWeight: 'bold'
            }}
          >
            <Typography variant="p">{moment(event.date).format('L')}</Typography>
            <Typography variant="p">
              {moment(event.date).format('h:mm A')} {event.time_zone}
            </Typography>

            <Typography variant="p">
              {event.city}, {event.state}
            </Typography>
          </div>
          <Button
            type="submit"
            variant="contained"
            color="blue"
            sx={{ width: 315, padding: 1, textTransform: 'none' }}
            onClick={() => window.open(event.ics_file, '_blank')}
          >
            Add this event to your calendar
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ThankYouComponent;

// https://marclloyd.co.uk/javascript/creating-an-add-to-calendar-link-in-react/
