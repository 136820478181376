import _ from 'lodash';
import { Select, MenuItem, InputLabel } from '@mui/material';
import { Controller } from 'react-hook-form';

const SelectDropdown = ({
  control,
  name,
  label,
  placeholder,
  rules,
  options,
  fullWidth,
  wrapperStyles,
  inputStyles,
  error,
  errorMessage,
  disabled
}) => {
  const selectOptions = _.isObject(_.first(options))
    ? options
    : options.map((option) => ({ id: option, name: option }));

  return (
    <div
      style={{ width: fullWidth && '100%', margin: 5, backgroundColor: 'white', ...wrapperStyles }}
    >
      <div className="flex">
        {label && (
          <InputLabel className="formLabel" style={{ opacity: disabled && 0.6 }}>
            {`${label}${rules && rules.required ? '*' : ''}`}
          </InputLabel>
        )}
        {error && (
          <span style={{ fontSize: 14, color: '#d32f2f', marginLeft: 20, fontStyle: 'italic' }}>
            {errorMessage}
          </span>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ...field } }) => {
          // console.log('field', field);
          return (
            <Select
              {...field}
              sx={{ ...inputStyles }}
              fullWidth={fullWidth}
              size="small"
              color="blue"
              error={Boolean(error)}
              disabled={disabled}
              displayEmpty
              renderValue={(selected) => {
                const selectedOption = _.find(selectOptions, { id: selected });
                if (!selected || !selectedOption) {
                  return <span>{placeholder}</span>;
                }
                return selectedOption.name;
              }}
            >
              {selectOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </div>
  );
};

export default SelectDropdown;
