import { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import RegFormComponent from '../common/RegFormComponent';

import styles from '../styles/regPage.module.scss';
import { FooterComponent } from '../common';
import config from '../config';

const combinedLogos = require('../assets/combinedLogosColor.png');

const RegPageComponent = () => {
  const location = useLocation();
  const [event, setEvent] = useState(location.state && location.state.session);

  const { sessionCode } = useParams();

  useEffect(() => {
    if (!event) {
      fetch(`${config.baseUrl}/api/events/${sessionCode}`)
        .then((res) => res.json())
        .then((eventJson) => {
          setEvent(eventJson);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [event, sessionCode]);

  if (!event) {
    return <h1>loading</h1>;
  }

  return (
    <div>
      <div className="flex direction-column">
        <div className={styles.header}>
          <img src={combinedLogos} alt="Combined logos" className={styles.logo} />
        </div>
        <div className={`flex direction-column vertical-center ${styles.regPageWrapper}`}>
          <div className={styles.eventHeader}>
            <Typography variant="h5" color="text.turquoise" sx={{ fontWeight: 600 }}>
              {event.name}
            </Typography>
            <div
              className="flex"
              style={{ justifyContent: 'space-between', minWidth: 300, marginBottom: 20 }}
            >
              <Typography variant="p">{moment(event.date).format('L')}</Typography>
              <Typography variant="p">
                {moment(event.date).format('h:mm A')} {event.time_zone}
              </Typography>
              <Typography variant="p">
                {event.city}, {event.state}
              </Typography>
            </div>
          </div>
          <RegFormComponent sessionCode={sessionCode} event={event} />
          <FooterComponent />
        </div>
      </div>
    </div>
  );
};

export default RegPageComponent;
