import _ from 'lodash';

import styles from '../styles/footer.module.scss';

const seagenLogo = require('../assets/seagen_logo.png');
const genmabLogo = require('../assets/genmab_logo.png');
const bottomLogos = require('../assets/combinedLogosColor.png');
const footerBorder = require('../assets/footer-border.png');

const FooterComponent = () => {
  return (
    <div className={styles.footerWrapper}>
      <img src={footerBorder} alt="footer border" style={{ width: '100%', display: 'block' }} />
      <div className={styles.footerContainer}>
        <div className="flex-center" style={{ margin: 15 }}>
          <a href="https://www.seagen.com/" target="_blank" rel="noreferrer">
            <img src={seagenLogo} alt="Seagen" className={styles.bottomLogo} />
          </a>
          <div style={{ height: 60, borderLeft: '2px solid #CCCCCC', margin: '0 20px' }} />
          <a href="https://www.genmab.com/" target="_blank" rel="noreferrer">
            <img src={genmabLogo} alt="Genmab" className={styles.bottomLogo} />
          </a>
          {/* <img src={bottomLogos} alt="Combined logos" className={styles.bottomLogo} /> */}
        </div>
        <div className="flex" style={{ justifyContent: 'center' }}>
          <a
            className={styles.linkList}
            href="https://www.seagen.com/terms"
            target={'_blank'}
            rel={'noreferrer'}
          >
            Terms of Use
          </a>
          <a
            className={styles.linkList}
            href="https://www.seagen.com/privacy"
            target={'_blank'}
            rel={'noreferrer'}
          >
            Privacy Policy
          </a>
          <a
            className={styles.linkList}
            href="https://www.seagen.com/contact"
            target={'_blank'}
            rel={'noreferrer'}
          >
            Contact Us
          </a>
        </div>
        <div>
          <p style={{ textAlign: 'center', fontSize: 14 }}>
            Seagen and its logo are US registered trademarks of Seagen Inc. Genmab; the Y-shaped
            Genmab logo; and Genmab in combination with the Y-shaped Genmab logo are trademarks for
            Genmab A/S and/or its subsidiaries.
          </p>
        </div>
        <div>
          <p style={{ textAlign: 'center', fontSize: 14 }}>
            &copy;2022 Seagen Inc. and Genmab US, Inc. All rights reserved. US-TVP-22-230-MT
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
