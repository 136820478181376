import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { useParams, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import { Typography, Grid, Paper, Button, Dialog } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { styled } from '@mui/material/styles';

import { SessionSelect } from '../common';
import RegFormComponent from '../common/RegFormComponent';

import config from '../config';

import styles from '../styles/checkin.module.scss';

const combinedLogos = require('../assets/combinedLogosColor.png');

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  // color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '25px'
}));

const CheckInComponent = () => {
  // const location = useLocation();
  const [regModalOpen, setRegModalOpen] = useState(false);
  const [registrants, setRegistrants] = useState({});
  const [attendee, setAttendee] = useState({});
  const [event, setEvent] = useState(null);

  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('first_name');
  const [sortOrder, setSortOrder] = useState('asc');

  const navigate = useNavigate();

  const { sessionCode } = useParams();

  useEffect(() => {
    if (sessionCode) {
      const getRegistrants = async () => {
        return fetch(`${config.baseUrl}/api/events/${sessionCode}/registrants`).then((res) => {
          return res.json();
        });
      };
      // console.log('event', event, 'sessionCode', sessionCode);
      // if (!event && sessionCode) {
      getRegistrants()
        .then((eventJson) => {
          setEvent(eventJson);
          setRegistrants(_.keyBy(eventJson.registrants, 'id'));
        })
        .catch((err) => {
          console.log(err);
        });

      const interval = setInterval(() => {
        getRegistrants()
          .then((eventJson) => {
            eventJson.registrants.forEach((reg) => {
              setRegistrants((prevRegistrants) => {
                const newRegistrants = { ...prevRegistrants };
                if (!newRegistrants[reg.id]) {
                  newRegistrants[reg.id] = reg;
                }
                return newRegistrants;
              });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [sessionCode]);

  if (sessionCode && !event) {
    return <h1>loading</h1>;
  }

  if (!sessionCode) {
    return (
      <div className="flex horizontal-center vertical-center">
        <SessionSelect
          onSubmit={(data) => {
            navigate(`/check-in/${data.sessionCode}`);
          }}
          buttonLabel="Select a session for check-in &gt;"
        />
      </div>
    );
  }

  const renderRegModal = () => {
    return (
      <Dialog
        open={regModalOpen}
        onClose={() => setRegModalOpen(false)}
        maxWidth={'md'}
        minWidth={'md'}
      >
        <div
          style={{
            position: 'relative',
            paddingTop: 20,
            // eslint-disable-next-line global-require
            backgroundImage: `url(${require('../assets/backgroundWBar.png')})`,
            backgroundSize: 'cover'
          }}
        >
          <Button
            className="flex-center direction-column"
            sx={{ position: 'absolute', top: 5, right: 3, color: 'black' }}
            onClick={() => setRegModalOpen(false)}
          >
            <CloseIcon />
            Close
          </Button>

          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" color="text.turquoise" sx={{ fontWeight: 600 }}>
              {_.isEmpty(attendee) ? 'Check In  New Attendee' : 'Check In'}
            </Typography>

            <Typography variant="p" component="div" style={{ fontWeight: 'bold' }}>
              {event.name}
            </Typography>
            <div
              className="flex-center"
              style={{
                justifyContent: 'space-evenly',
                width: '70%',
                margin: '0 auto',
                marginTop: 10,
                fontWeight: 'bold'
              }}
            >
              <Typography variant="p">{moment(event.date).format('L')}</Typography>
              <Typography variant="p">
                {moment(event.date).format('h:mm A')} {event.time_zone}
              </Typography>
              <Typography variant="p">
                {event.city}, {event.state}
              </Typography>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RegFormComponent
              isCheckIn
              submitButtonLabel="Check In"
              sessionCode={sessionCode}
              event={event}
              onSubmit={(data) => {
                // console.log('data in checkin submit', data);
                setRegModalOpen(false);
                setAttendee({});
                setRegistrants({ ...registrants, [data.id]: data });
              }}
              userData={attendee}
            />
          </div>
          {renderFooter()}
        </div>
      </Dialog>
    );
  };

  const filterAndSortRegistrants = () => {
    const getFieldValue = (registrant, field) => {
      // console.log('field', field, registrant[field]);
      if (registrant[field] === null) {
        return '';
      }
      if (_.isFunction(registrant[field].toLowerCase)) {
        return registrant[field] ? registrant[field].toLowerCase() : '';
      }
      return registrant[field];
    };

    // eslint-disable-next-line no-shadow
    let allRegistrants = _.values(registrants);

    if (searchValue) {
      allRegistrants = allRegistrants.filter((registrant) => {
        return (
          getFieldValue(registrant, 'first_name').includes(searchValue.toLowerCase()) ||
          getFieldValue(registrant, 'last_name').includes(searchValue.toLowerCase()) ||
          getFieldValue(registrant, 'state').includes(searchValue.toLowerCase()) ||
          getFieldValue(registrant, 'license_number').includes(searchValue.toLowerCase()) ||
          getFieldValue(registrant, 'npi').includes(searchValue.toLowerCase())
        );
      });
    }

    if (sortField) {
      allRegistrants = _.orderBy(allRegistrants, (elem) => getFieldValue(elem, sortField), [
        sortOrder
      ]);
    }

    // const sortedRegistrants = _.sortBy(
    //   _.filter(registrants, (reg) => reg.checkedIn === false),
    //   'lastName'
    // );
    return allRegistrants;
  };

  const applySortField = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const renderGridHeader = (label, fieldName) => {
    return (
      <div
        onClick={() => applySortField(fieldName)}
        style={{
          cursor: 'pointer',
          // border: '1px solid red',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        role="button"
        tabIndex={0}
      >
        <Typography variant="p" color={sortField === fieldName ? 'text.turquoise' : 'text.gray'}>
          {label}
        </Typography>
        {sortField === fieldName &&
          (sortOrder === 'asc' ? (
            <KeyboardArrowUpIcon color="turquoise" sx={{ position: 'relative', top: -2 }} />
          ) : (
            <KeyboardArrowDownIcon color="turquoise" sx={{ position: 'relative', top: -2 }} />
          ))}
      </div>
    );
  };

  const renderRegistrants = () => {
    if (_.isEmpty(registrants)) {
      return (
        <div
          style={{ textAlign: 'center', height: 200, padding: 40, fontSize: 24, fontWeight: 400 }}
        >
          No Registrants found for this event!
        </div>
      );
    }

    return filterAndSortRegistrants().map((registrant) => {
      return (
        <Item key={registrant.id} elevation={8} sx={{ padding: 2, margin: 1 }}>
          <Grid container spacing={2} columns={{ xs: 12 }}>
            <Grid item xs={2}>
              <Typography variant="p" color="text.primary">
                {registrant.first_name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="p" color="text.primary">
                {registrant.last_name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="p" color="text.primary">
                {registrant.state}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="p" color="text.primary">
                {registrant.license_number || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="p" color="text.primary">
                {registrant.npi || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {registrant.is_checked_in ? (
                <CheckCircleOutlineIcon
                  color="turquoise"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setAttendee(registrant);
                    setRegModalOpen(true);
                  }}
                />
              ) : (
                <Button
                  variant="contained"
                  color="turquoise"
                  sx={{ position: 'relative', top: -6 }}
                  onClick={() => {
                    setAttendee(registrant);
                    setRegModalOpen(true);
                  }}
                >
                  Check In
                </Button>
              )}
            </Grid>
          </Grid>
        </Item>
      );
    });
  };

  const renderFooter = () => {
    return (
      <div className={styles.footer}>
        Seagen and its logo are US registered trademarks of Seagen Inc. Genmab; the Y-shaped Genmab
        logo; and Genmab in combination with the Y-shaped Genmab logo are trademarks for Genmab A/S
        and/or its subsidiaries. <br />
        <br />
        &copy;2022 Seagen Inc. and Genmab US, Inc. All rights reserved. US-TVP-22-231-MT
      </div>
    );
  };

  return (
    <div className={styles.checkInWrapper}>
      <div className="flex direction-column vertical-center">
        <div className={styles.checkInHeader}>
          <div>
            <Typography variant="h5" color="text.turquoise" sx={{ fontWeight: 600 }}>
              {event.name}
            </Typography>
            <Typography variant="p">{moment(event.date).format('L')}</Typography>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Typography variant="p">
              {moment(event.date).format('h:mm A')} {event.time_zone}
            </Typography>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <Typography variant="p">
              {event.city}, {event.state}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="turquoise"
                sx={{ height: '100%', padding: 1.9, marginRight: 2, textTransform: 'none' }}
                onClick={() => {
                  setAttendee({});
                  setRegModalOpen(true);
                }}
              >
                Check in New Attendee
              </Button>
              <FormControl sx={{}} variant="outlined">
                <InputLabel color="turquoise" htmlFor="outlined-adornment-search">
                  Search Attendees
                </InputLabel>
                <OutlinedInput
                  color="turquoise"
                  id="outlined-adornment-search"
                  value={searchValue}
                  onChange={(evt) => {
                    setSearchValue(evt.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchOutlinedIcon color="turquoise" />
                    </InputAdornment>
                  }
                  label="Search Attendees"
                />
              </FormControl>
            </div>
            <div className={styles.logoWrapper}>
              <img src={combinedLogos} alt="Combined logos" className={styles.logo} />
            </div>
          </div>
        </div>
        {renderRegModal()}
        <div style={{ minWidth: '100%', width: 1100 }}>
          <div style={{ backgroundColor: 'white' }}>
            <Item
              key={'header-1'}
              elevation={0}
              sx={{ padding: 2, width: '75%', margin: '0 auto' }}
            >
              <Grid container spacing={2} columns={{ xs: 12 }}>
                <Grid item xs={2} justifyContent="center">
                  {renderGridHeader('FIRST NAME', 'first_name')}
                </Grid>
                <Grid item xs={2}>
                  {renderGridHeader('LAST NAME', 'last_name')}
                </Grid>
                <Grid item xs={2}>
                  {renderGridHeader('STATE', 'state')}
                </Grid>
                <Grid item xs={2}>
                  {renderGridHeader('STATE LICENSE #', 'license_number')}
                </Grid>
                <Grid item xs={2}>
                  {renderGridHeader('NPI NUMBER', 'npi')}
                </Grid>
                <Grid item xs={2}>
                  {renderGridHeader('CHECKED IN', 'is_checked_in')}
                </Grid>
              </Grid>
            </Item>
          </div>

          <div
            style={{
              width: '75%',
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              marginBottom: 20
            }}
          >
            {renderRegistrants()}
          </div>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};

export default CheckInComponent;
